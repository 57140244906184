<template lang="pug">
.Match
  v-row 
    v-col.pt-3
      MatchCard(v-if="match && !match.opponentRemoved" :match="match" :prevRoute="prevRoute")
  .mt-12
    Header(v-if="match  && !match.opponentRemoved" link="Offerings" :title="`${candidateDisplayName} bietet`" subtitle="Qualifikationen" :percentage="Math.round(match.candidateScore.percentage)" :isDetailHeader="true")
  .offerings.mb-12
    .elements(v-if="match  && !match.opponentRemoved")
      cardPersonalData(:disableEdit="true" :user="match.candidate" v-if="!match.candidate.anonymous")
      cardEducation(:type='EducationType.SCHOOL' title='Schule' :educationItems="match.candidate.school" :userType="UserType.CANDIDATE" :disableEdit="true")
      cardEducation(:type='EducationType.EDUCATION' title='Ausbildung / Studium' :educationItems="orderedEducation" :userType="UserType.CANDIDATE" :disableEdit="true")
      cardExperience(:type='ExperienceType.PRACTICAL_EXPERIENCE' title='Praxiserfahrung' :experienceItems="orderedPracticalExperience" :userType="UserType.CANDIDATE" :disableEdit="true")
      cardExperience(:type='ExperienceType.OTHER_EXPERIENCE' title='Sonstige Erfahrung' :experienceItems="orderedOtherExperience" :userType="UserType.CANDIDATE" :disableEdit="true")
      cardSkill(:type='SkillType.LANGUAGES' title='Sprachen' :skillItems="orderedLanguages" :userType="UserType.CANDIDATE" :disableEdit="true") 
      cardSkill(:type='SkillType.SOFTWARE' title='Software' :skillItems="orderedSoftwareSkills" :userType="UserType.CANDIDATE" :disableEdit="true")
  .mt-12
    Header(v-if="match  && !match.opponentRemoved" link="Conditions" :title="`${candidateDisplayName} sucht`" subtitle="Jobwünsche" :percentage="Math.round(match.positionScore.percentage)" :isDetailHeader="true")
  .conditions(v-if="match  && !match.opponentRemoved")
    .elements
        cardConditions(:item="match.candidate.conditions" title="Gewünschte Tätigkeit" userType="candidate" :disableEdit="true")
  v-card(v-if="!loadingMatch && (!match || match.opponentRemoved)")
    v-card-text.text-center Dieses Match ist leider nicht mehr verfügbar
   
</template>
<script>
import CardConditions from '@/components/candidate/lookingFor/conditions/CardConditions';
import cardEducation from '@/components/candidate/offering/educations/CardEducation.vue';
import cardExperience from '@/components/candidate/offering/experiences/CardExperience.vue';
import cardPersonalData from '@/components/candidate/offering/personal/CardPersonalData.vue';
import cardSkill from '@/components/candidate/offering/skills/CardSkill.vue';
import Header from '@/components/navigation/Header.vue';
import { SkillType } from '@/types/skill';
import { EducationType } from '@/types/education';
import { ExperienceType } from '@/types/experience';
import { UserType } from '@/types/user';
import MatchCard from '@/components/business/matches/MatchCard.vue';
import {
  orderEducationItems,
  orderExperienceItems,
  orderSkillItems,
} from '@/utilities/Utils';

import Vue from 'vue';
export default Vue.extend({
  components: {
    CardConditions,
    cardExperience,
    cardPersonalData,
    cardSkill,
    cardEducation,
    Header,
    MatchCard,
  },
  data() {
    return {
      SkillType,
      ExperienceType,
      EducationType,
      UserType,
      loadingMatch: true,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.$store
      .dispatch('business/fetchMatch', this.$route.params.mid)
      .finally(() => (this.loadingMatch = false));
  },
  computed: {
    match() {
      return this.$store.getters['business/matchById'](this.$route.params.mid);
    },
    isLoading() {
      return this.$store.state.business.loading;
    },
    candidateDisplayName() {
      if (this.match.candidate.anonymous) {
        return 'Anonym';
      } else {
        return `${this.match.candidate.firstName} ${this.match.candidate.lastName}`;
      }
    },
    orderedLanguages: function() {
      return orderSkillItems(this.match.candidate.languages ?? []);
    },
    orderedSoftwareSkills: function() {
      return orderSkillItems(this.match.candidate.softwareSkills ?? []);
    },
    orderedEducation: function() {
      return orderEducationItems(this.match.candidate.education);
    },
    orderedPracticalExperience: function() {
      return orderExperienceItems(this.match.candidate.practicalExperience);
    },
    orderedOtherExperience: function() {
      return orderExperienceItems(this.match.candidate.otherExperience);
    },
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {},
  watch: {
    match: {
      immediate: true,
      handler(match) {
        if (match && !match.seen) {
          this.$store.dispatch('business/markMatchSeen', match.id);
        }
      },
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.margin-top
  margin-top: 20px
</style>
